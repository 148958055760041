@import '../../variables';

.BaseSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 400; 
  font-family: $font-family-specialelite; 
  margin: .5em 0; 
  
  .BaseSectionHeader-container {
    display: flex;
    align-items: center;
  }

  .BaseSectionHeader-primary {
    color: $primary;
    font-size: $fontSizeTitleWeb;

    @media (max-width: $breakpoint-mobile) {
      font-size: $fontSizeTitleMobile;
    }
  }

  .BaseSectionHeader-secondary {
    color: $contrast-dark;
    font-size: 80%;
    white-space: nowrap;
    
    &:hover {
      opacity: 0.5;
    }
  }
}
