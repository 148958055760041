.AvailableOptions,
.LegalNotice {
  max-width: 100vw;
}
.f2257 {
  display: none;
  visibility: hidden;
}

.TimSiteFooter { 

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }

  footer {
    width: 100%;
    margin: 0 0 0 0;
    /* padding: 0 0 50px 0; */
    border-top: 1px solid #ae110f;
    background: #282828;
  }

  .timglobal-block-h3 {
    display: inline-block;
    margin-top: 10px;
    color: #777;
    font-size: 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }

  ul.menu {
    border: none;
    list-style: none;
    text-align: left;
  }

  li.leaf {
    margin-left: -40px;
  }

  footer a {
    color: #a6a6a6;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
  }

  .row.timglobal-row-page-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 15px;
    margin-left: 15px;
  }
  .row.timglobal-row-page-footertop .col-xs-12,
  .col-xs-12 {
    width: 100%;
    margin: 0;
  }

  .center-block.d-block.img-responsive.img-fluid.mx-auto {
    width: 100%;
    display: block;
    max-width: 980px;
  }

  .center-block.d-block.img-responsive.mx-auto .img-fluid {
    display: inline-block;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }

  a,
  a:link,
  a:visited {
    color: #fdfdfd;
  }
  a {
    color: #a6a6a6;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
  }
  .mx-auto {
    width: 100%;
  }
  .center-block {
    display: block !important;
    margin: 0 auto !important;
  }

  .hidden {
    display: block;
  }

  img.img-responsive.img-fluid.mx-auto {
    display: block !important;
    margin: 0 auto !important;
  }

  .col-md-2 {
    width: 16.66666667%;
  }
}

@media screen and (max-width: 768px) {
  .TimSiteFooter { 
    .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0px;
      margin-left: 0px;
    }

    /*ul.menu { display: none; visibility: hidden; }*/
    .col-md-2 {
      width: 100%;
    }

    .hidden {
      display: none;
    }

    .hidden.show {
      display: block;
    }

    ul li {
      width: 100%;
    }

    footer ul li.leaf a,
    footer ul li.leaf a:link,
    footer ul li.leaf a:visited {
      text-align: left;
      color: #fdfdfd;
      font-size: 16px;
      line-height: 24px;
      display: block;
      padding: 10px;
      font:
        16px / 18px 'ClearSans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        sans-serif;
      -moz-box-shadow:
        inset 0px 1px 6px 0px rgba(0, 0, 0, 0.59),
        inset -1px -1px 6px 0px rgba(0, 0, 0, 0.37);
      box-shadow:
        inset 0px 1px 6px 0px rgba(0, 0, 0, 0.59),
        inset -1px -1px 6px 0px rgba(0, 0, 0, 0.37);
    }

    ul li.leaf a:hover,
    ul li.leaf a:focus,
    footer ul li.leaf a.active {
      line-height: 30px;
      display: block;
      font-size: 13px;
      text-decoration: none;
      width: 100%;
      text-align: left;
      background: #ae110f;
      background: -o-linear-gradient(-89deg, #de1b18 0%, #7e0606 100%);
      background: -moz-linear-gradient(-89deg, #de1b18 0%, #7e0606 100%);
      background: -ms-linear-gradient(-89deg, #de1b18 0%, #7e0606 100%);
      background: linear-gradient(-179deg, #de1b18 0%, #7e0606 100%);
      color: #fdfdfd;
    }

    img.center-block.d-block.img-responsive.img-fluid.mx-auto {
      width: 100%;
      display: block;
      max-width: calc(100% - 15%);
    }
  }
}
