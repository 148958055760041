@import '../../../variables';

.MainDashboardHeader {
  box-shadow: $navigation-box-shadow;

  .HeaderOptions {
    display: none;
    align-items: center;

    .HeaderNavCollapseIndicator {
      margin-right: 10px;
      font-size: 120%;
      color: $dark-blue;
    }
  }

  .Section {
    display: flex;
    justify-content: flex-end;

    .ApplicationLogo {
      margin-right: 20px;
    }

    .NavBar-Btn {
      margin-right: 15px;
      white-space: nowrap;
      color: $dark-blue;
      padding: 8px 0;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      font-family: $font-family-metropolis;
    }
    .NavBar-Btn:hover {
      color: $contrast;
    }

    .Separator {
      margin: 0 10px;
      color: $brown;
    }

    .MobileMenuBtn {
      display: none;
      color: $brown;
      font-size: 24px;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  &.Primary {
    background-color: $white;
    position: relative;
    z-index: 2;
  }

  .MobileSearchWrapper {
    background-color: rgba(249, 247, 248, 0.9);
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .MobileSearchWrapperInner {
      .search {
        .searchButton {
          display: none;
        }
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .Inner {
      .searchButton {
        &.MobileSearchBtn {
          display: none;
        }
      }
    }

    .HeaderOptions {
      display: flex;
    }
  }
}

// BackdropFilterMobile in <MainDashboardHeader />
.MobileSearchWrapper,
.Inner {
  @media (max-width: $breakpoint-tablet) {
    & {
      position: relative;
      z-index: 2;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .Inner .searchButton .MobileMenuBtn {
    display: block;
    color: $brown;
    font-size: 24px;
    margin-right: 10px;
  }

  .searchButton {
    width: 40px;
    color: $dark-blue;
    font-size: 18px;

    &.MobileSearchBtn {
      color: $white;
      display: block;
    }
  }

  .MainDashboardHeader .Section .MobileMenuBtn {
    display: block;
  }

  .BackdropFilterMobile {
    position: fixed;
    inset: 0;
    z-index: 1;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    box-sizing: border-box;
  }
}

.AgeVerifiedContainer {
  width: 2em;
  height: 1.8em;
  margin: 0 0 0 0.6em;
  padding: 0.1em;
  color: $white;
  background: $ageVerifiedIcon;
  border-radius: 0.3em;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0.8em;

  @media (min-width: $breakpoint-tablet) {
    right: 2px;
    top: 2px;
  }

  svg {
    vertical-align: middle;
    pointer-events: none; // datalayer click bug
  }

  .AgeVerifiedContent {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 3;
    width: 100vw;
    max-width: 14em;
    color: $white;
    background: $ageVerifiedIcon;
    padding: 0.5em;
    border-radius: 0.3em;
    text-align: center;
    line-height: 1.5;
  }

  &:hover {
    .AgeVerifiedContent {
      display: block;
    }
  }
}
