@import '../../variables';

.BoxCoverPlayerDetailsComponent {
  .FullPlayer {
    height: 100%;

    .vjs-poster {
      background-size: cover;
    }
  }

  .MoviePlayerRow,
  .FullPlayerContainer {
    position: relative;
  }

  .FullPlayerContainer {
    aspect-ratio: 16 / 9;

    .PreviewMaxCountOverlay {
      position: absolute;
      z-index: 2;
      inset: 0;
      display: flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .PreviewMaxCountOverlayText {
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0.3em;
        margin: auto;
        padding: 0.2em 0.8em;
        color: $white;
        background: rgba(0, 0, 0, 0.5);
        font-size: 130%;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .VideoTitle {
    margin-bottom: 0.5em;
    color: $videoDetailstItems;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 0.5em;
    }

    h4 {
      display: inline-block;
      margin: 0 0.5em;
      font-size: 100%;

      @media (min-width: $breakpoint-tablet) {
        font-size: 130%;
      }
    }

    .NowPlaying {
      text-transform: uppercase;

      @media (min-width: $breakpoint-tablet) {
        font-size: 90%;
      }
    }

    .Duration {
      font-size: 80%;
      opacity: 0.5;
    }
  }

  .VideoTitleLoader {
    max-width: 400px;
    height: 35px;
    margin: 10px 0;
  }

  .PlayerInfoIconsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 0.5em;
    margin-bottom: -25px;
    margin-top: 5px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 0.5em;
    }

    .GalleryIcon {
      color: $videoDetailstItems;
      cursor: pointer;
      font-size: 36px;
    }

    .GoToMovieIcon {
      width: 35px;
      height: 35px;
      color: $videoDetailstItems;
    }

    .DownloadIcon:hover,
    .GalleryIcon:hover,
    .GoToMovieIcon:hover {
      opacity: 0.7;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .PlaylistsPlayerPopup {
      right: 0.5em;

      &::after {
        display: none;
      }
    }
  }
}

.NotAvailable {
  height: 50vh;
  text-transform: uppercase;
  display: flex;
  font-size: 150%;
  padding: 0 0.5em;
  text-align: center;

  .ClickToHomePage {
    margin: auto;
    cursor: pointer;
    color: $light-blue;

    &:hover {
      color: $brown;
    }
  }
}
