@import '../../../variables';

.SSOPage {
  min-height: 250px;
  padding: 1em 0.5em;
  text-align: center;

  .SSOMessage {
    margin: 2em 0;

    a {
      color: inherit;
      opacity: 0.7;
      text-decoration: none;
      font-style: italic;

      &:hover {
        opacity: 1;
      }
    }
  }
}
