@import '../../variables';

.SceneView {
  .PosterContainer {
    width: 28%;
  }
}

.MovieView {
  .Title {
    font-weight: 600;
  }
}

.MovieBoxCoverStillDisplay {
  font-size: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .PosterImage {
    width: 100%;
    height: 100%;
  }

  .PlayerContainer {
    width: 69%;
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;

    &.FullWidth {
      width: 100%;
    }
  }

  .Duration {
    position: absolute;
    bottom: 0.8em;
    right: 0.8em;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-shadow: 0 0 5px #000;
  }

  .SceneImageHoverPlayer {
    height: 100%;
  }

  .MissingImage {
    color: #000;
    text-shadow: 0.3px 0.3px #fff;
    padding: 5px 10px;
    font-size: 110%;
  }
}

.MovieBoxCoverStillDisplayInfos {
  display: flex;
  margin-top: 5px;

  .Title {
    text-transform: uppercase;
    color: $primary-text;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .StarsContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    .Stars {
      color: $contrast;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.BoxCoverStillDisplayImageOverlay {
  position: absolute;
  inset: 0;
  z-index: 1;
}
