@import '../../variables';

.MainSearch {
  text-transform: uppercase;
  font-family: Arial;
  max-width: 25em;
  margin: auto;

  .SearchInputContainer {
    width: 100%;

    @media (min-width: $breakpoint-tablet) {
      & {
        position: relative;
      }
    }
  }

  .DropdownSelect {
    margin: 0 5px; 
  }

  .searchTerm {
    padding: .5em;
    outline: none;
    border: none;
    font-size: 18px;
    text-transform: uppercase;
    background-color: transparent;
    text-transform: inherit;
    width: 100%;
    font: inherit;
    box-sizing: border-box; 
  }

  .SearchContainer { 
    border: 2px solid $dark-blue;
    width: 100vw;
    position: relative;  
    display: flex;
 

    &.hasDropdown {
      font-weight: 800;
      border: none;
      border-bottom: 2px solid $dark-blue;

      @media (max-width: $breakpoint-tablet) {
        & {
          font-size: 90%;
        }
      }
    }
  }

  &.isOpen {
    .SearchContainer {
      display: flex;
    }
  }

  .searchButton {
    border-radius: 10px;
    border: none;
    width: 40px;
    height: 36px;
    color: $dark-blue;
    font-size: 20px;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .SearchResults {
    padding: 10px;
    background: $white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 3px solid $black;
    border-top-width: 1px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2000;
    justify-content: space-between;
    font-weight: bold;
    width: 100%;
    top: 31px;
    user-select: none;
    text-transform: none;
    max-height: 400px;
    overflow: auto;
    box-sizing: border-box;

    .SearchResultsSection {
      display: flex;
      flex-direction: column;

      .SearchResultsSectionHeader {
        color: $gray;
      }

      .SearchResultItem {
        cursor: pointer;
        margin-bottom: 5px;
        color: $dark-blue;
        margin-left: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .NoResults {
    position: absolute;
    z-index: 2000;
    top: 105%;
    left: 0;
    outline: 2px solid $dark-blue;
    padding: 10px;
    background: $white;
    color: $dark-blue;
    border-top-width: 1px;
    font-size: .7em;
    font-weight: bold;
    width: 100%;
  }

  &.MobileSearch {
    display: flex;
    width: 100%;
    margin-right: 0;

    .searchButton {
      display: none;
      color: transparent;
      background-color: transparent;

      .fa-search {
        color: $white;
      }
    }

    .search {
      display: flex;
      width: 100%;
      border: none;
      background: none;

      .searchTerm {
        display: block;
      }
    }

    .DropdownSelect { 
      margin-left: 5px;
      margin-right: 10px;
    } 
  }

  &.isOpen {
    .search {
      width: 100vw;
      max-width: 85%;
    }

    .searchTerm {
      display: block;
      border-bottom: 1px solid $dark-blue;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .DropdownSelect {
      display: flex;
    }
  }

  &.isSearching {
    .DropdownSelect:hover .DropdownSelectContent {
      display: none;
    }
  }

  &.SearchResultsVisible {
    .search {
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}