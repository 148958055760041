@import '../../variables';

.StreamTest {
  padding: 0.5em 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 1em 1em 2em;
  }

  .AccountContainer {
    display: inline-block;
    text-transform: uppercase;
    background-color: $primary;
    color: $white;
    font-family: sans-serif;
    font-size: 95%;
    border-radius: 0.3em;
    // move to left
    position: relative;
    left: 100%;
    transform: translateX(-100%);

    .AcctionBtn {
      color: inherit;
      text-decoration: none;
    }

    span,
    .AcctionBtn {
      vertical-align: middle;
      display: inline-block;
      padding: 0.4em 0.5em;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      i {
        font-size: 60%;
      }
    }
  }

  .ModalDialog {
    top: 70px;
  }
}
