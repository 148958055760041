@import '../../variables.scss';

.ErrorBoundary {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:not(:last-child) {
    margin: 0 0 4em;
  }

  .LogoWrapper {
    pointer-events: none;

    .ApplicationLogo {
      display: flex;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        background: rgba(0, 0, 0, 0.5);
        padding: 1em;
      }
    }

    svg {
      max-width: 280px;
    }
  }

  .TextWrapper {
    p {
      font-size: 1.4em;
      margin: 0 0 0.5em;

      @media (max-width: $breakpoint-mobile) {
        & {
          font-size: 1.2em;
        }
      }
    }
  }

  .SiteName {
    font-size: 1em;
  }
}

.ErrorMessage {
  text-align: center;
  padding: 3em 0 2em;
  font-size: 1.2em;
}
