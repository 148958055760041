@import '../../../../variables';

.StarDetails {
  padding: 1em 0;
  background-color: $background;

  .StarHeaderImage {
    width: 100%;
  }
}
