@import '../../../variables';

.MovieDetailsPage {
  .PageTitle {
    font-family: $font-family-specialelite;
    font-weight: 600;
    font-size: 160%;
    color: inherit;
  }

  @media (min-width: $breakpoint-mobile) {
    .MoviesScenesGrid {
      padding: 0;

      .Grid,
      .PlaceholdersGrid {
        grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
      }
    }
  }

  @media (min-width: $breakpoint-mobile) {
    margin-top: 30px;
    padding: 0 10px 50px;
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 0 20px 50px;
  }
}
