@import '../../variables';

.Tim-VideoVault .TimNavContainer .MainSearch .SearchResults {
  padding: 10px;
  background: #fff;
  outline: 0 solid #000000;
  border: 2px solid #000000;
  border-top-width: 0;
  position: absolute;
  z-index: 2000;
  font-weight: 600;
  width: 100%;
  top: 28px;
  left: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 400px;
  overflow: auto;
  box-sizing: border-box;
  line-height: 1.15;
  background: rgba(255, 255, 255, 0.95);
  font-size: 12px;
}

.Tim-VideoVault .NoResults {
  position: absolute;
  z-index: 2000;
  top: 28px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 3px solid $black;
  padding: 10px;
  background: $white;
  color: $dark-blue;
  border-top-width: 0;
  font-size: 20px;
  font-weight: bold;
  width: calc(100% - 26px);
}

/*
.BrowseNavigationText, .Arrow { display: none; visiblity: hidden; }
*/

.Tim-VideoVault .SearchItems {
}

/*
.Tim-VideoVault .MainSearch.isOpen .DropdownSelect {
	display: block;
	margin-left: -40px;
	margin-bottom: -30px;
	margin-top: 20px;
	position: absolute;
	left: 20px;
}
*/

.Tim-VideoVault .DropdownSelect .Arrow {
  border-right: 6px solid transparent;
  border-top: 10px solid #fff;
  border-left: 6px solid transparent;
  right: 0;
  position: absolute;
  margin: -15px 11px 0 0;
}

.Tim-VideoVault .MainSearch .search {
  position: relative;
  display: flex;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
}

.Tim-VideoVault .MainSearch .DropdownSelect {
  display: block;
  max-width: 100px;
  margin: -15px 0 -50px -100px;
}

.TimNavContainer .MainSearch .search {
  width: 55vw;
  margin-top: 20px;
  display: block;
}

/* override - MainDashboard.scss */
.MainDashboard .PrimaryHeader {
  padding: 0 !important;
}

.Tim-VideoVault .MainDashboard {
  background-color: transparent !important;
}

.Tim-VideoVault .MainDashboard .MainContent {
  background-color: transparent;
}

.AppLogo {
  background: #0066ff;
}

.Tim-VideoVault .TimMainDashboardHeader.Primary.PrimaryHeader {
  visibility: hidden;
  display: none;
}

.TimNavContainer {
  display: flex;
  width: 100%;

  .BrowseNavigation {
    display: none;
    visibility: hidden;
  }

  .MainSearch .searchButton {
    border-radius: 4px;
    border: none;
    width: 40px;
    height: 36px;
    color: #ffffff;
    font-size: 20px;
    background: transparent;
    //display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
    visibility: hidden;
  }

  .MainSearch .searchTerm {
    display: block;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    margin: 5px 0 0 0;
    outline: none;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #ffffff; 
  }

  .MainSearch .search {
    width: 55vw;
  }

  .MainSearch.isOpen .searchTerm {
    border-bottom: 0 solid $black;
  }

  .slick-slider {
    position: relative;
    display: block;
    background: #000;

    padding: 0;
    width: 100vw;
    margin: 30px 0 0 -50px;

    .slick-list {
      margin-top: 0;
      min-height: 45px;

      .text-info {
        min-height: 45px;

        .nav-sub-link span {
          font-size: 18px;
          display: block;
          padding: 10px 0 0 0;
          font-family: 'ClearSans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .slick-list,
    .slick-slider {
      margin: 0 0 0 -90px;
    }
    .tab-content {
      text-align: center;
      background: #000000;
    }
  }

  @media (max-width: 960px) {
    .tab-content {
      text-align: center;
      background: transparent;
    }
  }
  .tab-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .tab-list-item {
    margin: 0;
    list-style: none;
    color: #ffffff;
    box-sizing: content-box;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    font-size: 16px;
  }

  .tab-list-active,
  .tab-list-item:hover {
    background: linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
    margin: 0 1px;
    cursor: pointer;
    border: none;
  }

  .tab-list-item:not(#TimNav-Videovault) {
    border-left: 1px solid #404040;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow:
      inset 0 1px 6px 0 rgba(0, 0, 0, 0.59),
      inset -1px -1px 6px 0px rgba(0, 0, 0, 0.37);
    font-family: 'ClearSans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px / 16px;
    font-weight: 600;
  }

  #TimNav-VideoVault {
    background-image: url('../../images/timvideovaultlogo-62h.png');
    background-size: contain;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
    color: rgba(0, 0, 0, 0);
    padding: 0.5rem 0.75rem;
  }

  #TimNav-VideoVault.tab-list-active {
    background-image: url('../../images/timvideovaultlogo-62h.png'),
      linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0;
    list-style: none;
    padding: 0.5rem 0.75rem;
    color: rgba(0, 0, 0, 0);
    width: 100%;
    text-align: center;
  }

  .slick-slider div {
    padding: 0;
    margin: 0;
  }

  .tab-content .slick-slide a:hover span {
    color: #ffffff !important;
    text-decoration: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
  }

  .column.skull {
    width: 88px;
    flex: 0 0 88px;
    max-height: 60px;
    margin: 0;
    padding: 0;
    background: linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
    background-size: cover;
    cursor: pointer;
  }


  .column.skull:hover {
    cursor: pointer;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /*

        .tab-list {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: stretch;
            width: 100%;
            margin:0;
            padding:0;
        }

        .tab-list-item {

            margin: 0;
            list-style: none;
            padding: 0.5rem 0.75rem;
            color: #ffffff;
            box-sizing:border-box;
            width: 100%;
            height: 62px;
            text-align:center;
        }


        .tab-list-active {
            background: linear-gradient(180deg, rgba(220,27,24,1) 0%, rgba(139,9,9,1) 100%);
            margin: 0;
            list-style: none;
            padding: 0.5rem 0.75rem;
            color: #ffffff;
            width: 100%;
            text-align: center;

        }
        */

  .timnavmain.TabsWrap {
    width: calc(100% - 90px);
  }

  .tab-content a,
  .tab-content a:link,
  .tab-content a:visited,
  .tab-content a:focus {
    color: rgb(201, 124, 42);
    font-weight: 500;
    text-decoration: none;
    padding: 0px;
    margin: 0;
    text-transform: uppercase;
    display: block;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-slide:hover {
    color: #ffffff !important;
    background: linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
    background-size: cover;
    width: 100%;
    cursor: pointer;
  }

  .slick-slide:hover .navlink .text-info a.nav-sub-link span {
    color: #ffffff !important;
  }

  .NavLink span:hover,
  .slick-slider .NavLink:hover {
    color: #ffffff;
  }

  .slick-slider .nav-sub-link span:hover,
  .navlink .text-info:hover {
    color: #ffffff;
  }

  .slick-slider .nav-sub-link span {
    font-size: 14px;
    display: inline-block;
    font-family: 'ClearSans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }

  .slick-prev {
    left: 20px;
    z-index: 2;
    position: absolute;
  }
  .slick-next {
    right: 20px;
    z-index: 2;
    position: absolute;
  }
  ƒ .slick-prev:before,
  .slick-next:before {
    font-family: 'Font Awesome 5 Free', helvetica, sans-serif;
    opacity: 0.75;
    color: #c97c2a;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .slick-next:before {
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    content: '\f105';
  }

  .slick-prev:before {
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    content: '\f104';
  }

  /*
	.MainSearch .seaTimNav-Shoprch {
		width: 100vw;
		max-width: 85%;
		margin-top: -40px;
	}

	.MainSearch .searchTerm {
		display: block;
		border-bottom: 1px solid #112d5d;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.MainSearch .searchTerm {
		border-radius: 10px;
		width: 100%;
		padding: 5px;
		outline: none;
		border: none;
		font-size: 18px;
		text-transform: uppercase;
		background-color: transparent;
	}
	*/
}

/* search results desktop */

.HeaderOptions {
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 9999;
  top: 60px;
  width: 100vw;
  background: rgba(174, 17, 15, 0.75);
  min-height: 120px;
  margin: 0 0 0 -90px;
}

.DropdownSelect .SelectedTerm {
  font-size: 14px;
  color: $white;
}
.DropdownSelect .DropdownSelectContent .Option {
  font-size: 12px;
  padding: 2px;
  font-weight: 800;
}

.MainSearch {
  padding: 30px 0 0 0;
}

/**************************************** 960 ***********************************/

@media screen and (max-width: 960px) {
  /*
	.timnavmain.TabsWrap:not(#TimNav-VideoVault) { display: none; }

	.timnavmain.TabsWrap #TimNav-VideoVault { display: block !important; }
*/
  .TimNavContainer {
    display: flex;
    max-width: 100%;
    height: 62px;
    width: 100%;

    .HeaderOptions {
      position: absolute;
      display: block;
      cursor: pointer;
      z-index: 9999;
      top: -230px;
      width: 100vw;
      background: rgba(174, 17, 15, 0.75);
      min-height: 180px;
      margin: 0 0 0 -45px;
    }

    .MainSearch {
      padding: 40px 0 0 0;
    }

    .DropdownSelect .SelectedTerm {
      font-size: 14px;
    }
    .DropdownSelect .DropdownSelectContent .Option {
      font-size: 12px;
      padding: 2px;
      font-weight: 800;
    }

    /*
	.TabsWrap .tabs li:not(#TimNav-VideoVault), .TabsWrap .tabs .tab-content {
		display: none;
		visibility: hidden;	}
		*/
    .tab-list li:not(#TimNav-VideoVault),
    .tab-content {
      display: none;
      visibility: hidden;
    }
  }

  .TimNavContainer.Visible {
    display: flex;
    overflow: visible;
    max-width: 100%;
    height: 375px;
    min-height: 375px;
    width: 100%;
    .tabs li,
    .tab-list li,
    .tabs .tab-content {
      display: block;
      visibility: visible;
    }
    .tab-list-item:not(#TimNav-VideoVault) {
      text-align: center;
      width: 100%;
      display: flex;
      visibility: visible;
      margin: 0 0 0 0;
      padding: 0px 0 0 0;
    }
    .tab-list #TimNav-Shop {
      display: flex;
      visibility: visible;
    }
    .tab-list #TimNav-Channels {
      display: flex;
      visibility: visible;
    }
    .tab-list #TimNav-NEWS {
      display: flex;
      visibility: visible;
    }
    .tab-list #TimNav-BUTTON {
      display: flex;
      visibility: visible;
    }
    .tab-list #TimNav-Search {
      display: flex;
      visibility: visible;
    }
  }

  .fa-search {
    display: none;
    visibility: hidden;
  }

  .TimNavContainer .MainDashboardHeader .MobileSearchWrapper {
    background-color: rgba(249, 247, 248, 0.9);
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
  }

  .TimNavContainer .MainDashboardHeader.Primary.PrimaryHeader {
    background-color: #000000;
    position: absolute;
    top: 0.3em;
    right: 2em;
  }

  .TimNavContainer .MainDashboardHeader .Section .MobileMenuBtn {
    display: inline-block;
    color: #efefef;
    font-size: 24px;
    margin: 0.5em;
  }

  .TimNavContainer .tab-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    left: 0;
    position: absolute;
  }

  .TimNavContainer .tab-content {
    transform: translate(-40px, 300px);
  }

  .TimNavContainer #TimNav-VideoVault.tab-logo-nav {
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
  }

  .TimNavContainer .searchButton.MobileSearchBtn {
    color: #ffffff;
    display: none;
    width: 0;
  }

  #TimNav-Shop,
  #TimNav-Channels,
  #TimNav-NEWS,
  #TimNav-Search,
  .tab-content {
  }

  .SideMenu .slick-slider {
    visibility: hidden;
  }
  .SideMenu.Visible .slick-slider {
    visibility: visible;
  }
  .SideMenu.Visible .content {
    transform: translateY(260px);
  }

  .SideMenu.Visible .Section {
    display: none;
    visibility: hidden;
  }

  .SideMenu {
    .TimNavContainer,
    .TimNavContainer.TimNavMobile {
      display: flex;
      max-width: 100%;
      height: 62px;
      max-height: 62px;

      .timnavmain.TabsWrap {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .tab-list {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          align-content: stretch;
          width: 100%;
          margin: 120px 0 0 0;
          padding: 0;
        }

        /* , .tab-content */
        #TimNav-Shop,
        #TimNav-Channels,
        #TimNav-NEWS,
        #TimNav-Search {
          display: flex;
          visibility: visible;
          width: 100%;
          height: 62px;
          background: #000000;
        }

        #TimNav-Shop:hover,
        #TimNav-Channels:hover,
        #TimNav-NEWS:hover,
        #TimNav-Search:hover {
          background: transparent;
          background: linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
        }

        .tab-content a,
        .tab-content a:link,
        .tab-content a:visited,
        .tab-content a:focus {
          padding: 0;
        }

        .tab-content {
          display: block;
          visibility: hidden;
          height: 0;
          margin-top: -20px;
        }

        .navlink a.nav-sub-link span {
          display: block;
          width: 100vw;
          margin: 0;
          padding: 0;
          height: 28px;
          font-size: 12px !important;
          background: #000000;
          &:hover {
            color: #ffffff !important;
            background: linear-gradient(180deg, #dc1b18 0%, #8b0909 100%);
            background-size: cover;
            margin: 0;
            padding: 0;
            width: 100%;
            max-height: 28px;
            cursor: pointer;
          }
        }

        .Tim-VideoVault .TimMainDashboardHeader.Primary.PrimaryHeader {
          visibility: visible;
          display: block;
        }

        #TimNav-VideoVault.tab-list-active {
          background-image: url('../../images/timvideovaultlogo-62h.png'),
            linear-gradient(180deg, rgba(220, 27, 24, 1) 0%, rgba(139, 9, 9, 1) 100%);
          background-position: center center;
          margin: 0;
          list-style: none;
          padding: 0.5rem 1rem;
          color: rgba(0, 0, 0, 0);
          width: 100%;
          text-align: center;
        }

        .TimNavMobile {
          background: #000000;
        }
      }

      /* side menu */
    }

    /* TimNavContainer */
  }

  /*
	.MainDashboard #TimNav-VideoVault { display: block; visibility: visible; }

	.MainDashboard .MainContent .SideMenu #TimNav-VideoVault {

		display: none;
		visibility: hidden; position: absolute;
		z-index: 9999;
		background-image: url("../../images/timvideovaultlogo-62h.png"),linear-gradient(180deg, #dc1b18 0%, #8b0909 100%);
		background-position: center center;
		margin: 0;
		list-style: none;
		padding: 0.5rem 1rem;
		color: rgba(0, 0, 0, 0);
		width: 100%;
		text-align: center;}
		*/
}