@import '../../variables.scss';

.Footer { 
  border-top: 1px solid #cccccc;
  background: #121212;
  padding: 1em 0;  
  font-size: 16px; 

  @media (max-width: $breakpoint-tablet) {
    font-size: 90%;
  }  
} 
 
