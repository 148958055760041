@import '../../variables';

.AvsPage {
  padding: 1em 0.5em 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 0 2em 2em;
  }

  .ApplicationLogo {
    margin: 1em auto;
    display: block;

    @media (max-width: $breakpoint-tablet) {
      background-color: $primary;
      padding: 0.5em;
    }
  }

  .Link {
    color: $secondary;

    &:hover {
      opacity: 0.7;
    }
  }

  .AvsLinksContainer {
    text-align: center;

    .Link {
      text-transform: uppercase;
      margin: 0.5em 1em;
      display: inline-block;
    }
  }

  .Content {
    text-align: center;
    max-width: 55em;
    margin: 0 auto;

    .Heading {
      padding: 10px 0 5px 0;
      font-size: 1.25em;
    }

    .CustomerNote {
      font-size: 0.85em;
    }
  }

  .Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(345px, 20em));
    justify-content: center;
    gap: 1em;
  }

  .TextRed {
    color: red;
    font-weight: 600;
  }

  .StatusInfo {
    max-width: 40em;
    margin: 0 auto;
  }

  .VerifyAgeButton {
    border: none;
    text-transform: uppercase;
    border-radius: .3em;
    margin-bottom: .5em;
    padding: .3em;
    background-color: rgba(0, 0, 0, .2);
    color: #fff;
    cursor: pointer;
    font: inherit;
    width: 100%;

    &:hover {
      opacity: .8;
    }
  }
}